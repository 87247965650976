import React, { FunctionComponent } from "react"
import styles from "./benefits.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { Benefit } from "@features/support/support-types"
import { Button } from "@components/button/button"

type BenefitsProps = {
  title: string
  body: string
  benefits: Benefit[]
  benefitsButtonText: string
  benefitsButtonUrl: string
}
export const Benefits: FunctionComponent<BenefitsProps> = ({
  title,
  body,
  benefits,
  benefitsButtonUrl,
  benefitsButtonText
}) => (
  <div className={styles.partnerWithUs}>
    <div className={styles.content}>
      <div className={styles.text}>
        <Markdown className={styles.title} content={title} />
        <Markdown className={styles.body} content={body} />
      </div>
      <div className={styles.benefits}>
        {benefits.map((item, index) => (
          <div className={styles.benefit} key={index}>
            <div className={styles.image}>
              <img data-src={item.image} alt="" className="lazyload" />
            </div>
            <Markdown className={styles.body} content={item.text} />
          </div>
        ))}
      </div>
      <div className={styles.text}>
        <Button size="md" variant="primary" to={benefitsButtonUrl}>
          {benefitsButtonText}
        </Button>
      </div>
    </div>
  </div>
)
