import React, { FunctionComponent } from "react"
import { Story } from "../../about-types"
import styles from "./about-stories.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const CardStory: FunctionComponent<Story> = ({
  title,
  body,
  youtubeId
}) => (
  <div className={styles.cardStory}>
    <div className={styles.image}>
      <lite-youtube videoid={youtubeId}></lite-youtube>
    </div>
    <div className={styles.text}>
      <div className={styles.title}>{title}</div>
      <Markdown className={styles.body} content={body} />
    </div>
  </div>
)
