import React, { FunctionComponent, useState } from "react"
import slugify from "slugify"
import styles from "./faqs.module.scss"
import { AccordionItem } from "@components/accordion-item/accordion-item"

type FaQsProps = {
  questions: Array<AccordionItem>
  title: string
}

const createAnchorHash = (base: string) =>
  slugify(base, {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    trim: true
  })

export const FaQs: FunctionComponent<FaQsProps> = ({ questions, title }) => {
  const [anchorHashValue, setAnchorHashValue] = useState("")

  if (typeof window !== "undefined") {
    const hashValue = window.location.hash.slice(1)

    if (hashValue !== anchorHashValue) {
      setAnchorHashValue(hashValue)
    }
  }

  return (
    <section className={styles.accordion}>
      <div className={styles.title}>{title}</div>
      <div className={styles.accordionContent}>
        {questions.map(({ title, subtitle, image, body }: AccordionItem) => {
          const anchorHashKey = createAnchorHash(title)

          return (
            <div className={styles.callout} key={title} id={anchorHashKey}>
              <AccordionItem
                title={title}
                subtitle={subtitle}
                image={image?.fluid?.src}
                body={body?.childMarkdownRemark?.rawMarkdownBody || ""}
                defaultExpandState={anchorHashValue === anchorHashKey}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}
