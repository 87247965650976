import React, { FunctionComponent, useState } from "react"
import classNames from "classnames"
import styles from "./accordion-item.module.scss"
import { Icon } from "@components/icon/icon"
import { Markdown } from "@components/markdown/markdown"
import {
  stripAttributesFromTag,
  wrapElementInTag
} from "@features/posts/posts-helpers"

export interface AccordionItem {
  title: string
  subtitle: string
  body: {
    childMarkdownRemark: {
      rawMarkdownBody: string
    }
  }
  image: {
    fluid: {
      src: string
    }
  }
}

export interface AccordionItemProps {
  image: string
  title: string
  subtitle: string
  body: string
  defaultExpandState?: boolean
}

export const AccordionItem: FunctionComponent<AccordionItemProps> = ({
  image,
  title,
  subtitle,
  body,
  defaultExpandState = false
}) => {
  const [shouldRenderBody, setShouldRenderBody] = useState(defaultExpandState)

  const stripIframeWidthAndHeight = (htmlString: string): string => {
    return stripAttributesFromTag({
      htmlString,
      tag: "iframe",
      targetAttributes: "width height"
    })
  }

  return (
    <div
      className={styles.accordionItem}
      onClick={() => setShouldRenderBody(!shouldRenderBody)}
    >
      <img className={styles.accordionItemImg} src={image} />
      <div className={styles.accordionItemText}>
        <p className={styles.accordionItemTitle}>{title}</p>
        <p className={styles.accordionItemSubtitle}>{subtitle}</p>
        {shouldRenderBody && (
          <Markdown
            className={styles.accordionBodyCopy}
            content={wrapElementInTag({
              htmlString: stripIframeWidthAndHeight(body),
              selector: "iframe",
              wrapper: '<article data="iframe-container"></article>'
            })}
          />
        )}
      </div>
      <Icon
        className={classNames(
          styles.accordionItemIcon,
          shouldRenderBody && styles.opened
        )}
        variant="12-right-arrow"
      />
    </div>
  )
}
