import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { Activity } from "../../about-types"
import styles from "./what-we-do.module.scss"
import { Markdown } from "@components/markdown/markdown"
import buildImageUrl from "@utils/imageUrl"
import { Icon } from "@components/icon/icon"

export interface WhatWeDoProps {
  title?: string
  subtitle?: string
  mainActivities: Activity[]
  otherActivities?: Activity[]
}

export const WhatWeDo: FunctionComponent<WhatWeDoProps> = ({
  title,
  subtitle,
  mainActivities,
  otherActivities
}) => (
  <div className={styles.whatWeDo} id="what-we-do">
    <div className={styles.content}>
      {(title || subtitle) && (
        <div className={styles.text}>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          {title && <h3 className={styles.title}>{title}</h3>}
        </div>
      )}
      <div className={styles.mainActivities}>
        {mainActivities.map((activity, i) => (
          <div className={styles.activity} key={`${activity.title}-${i}`}>
            <img data-src={activity.image} alt="" className="lazyload" />
            <div className={styles.activityText}>
              <Markdown
                className={styles.activityName}
                content={activity.title}
              />
              <Markdown
                className={styles.activityBody}
                content={activity.body}
              />
            </div>
          </div>
        ))}
      </div>
      {otherActivities && (
        <div className={styles.otherActivities}>
          {otherActivities.map((activity, i) =>
            activity?.url ? (
              <Link
                className={styles.activity}
                key={`${activity.title}-${i}`}
                to={activity.url}
                target="_blank"
              >
                <div className={styles.image}>
                  {activity.image && (
                    <img src={activity.image} alt={activity.imageAltText} />
                  )}
                </div>
                <div className={styles.activityText}>
                  <div className={styles.flex}>
                    <Markdown
                      className={classNames(
                        styles.activityName,
                        styles.externalLinkName
                      )}
                      content={activity.title}
                    />
                    <Icon
                      variant="12-external-link"
                      className={styles.externalLinkIcon}
                    />
                  </div>
                  <Markdown
                    className={styles.activityBody}
                    content={activity.body}
                  />
                </div>
              </Link>
            ) : (
              <div className={styles.activity} key={`${activity.title}-${i}`}>
                <div className={styles.image}>
                  {activity.image && (
                    <img
                      src={buildImageUrl(activity?.image, {
                        width: 24
                      })}
                      alt={activity.imageAltText}
                    />
                  )}
                </div>
                <div className={styles.activityText}>
                  <Markdown
                    className={styles.activityName}
                    content={activity.title}
                  />
                  <Markdown
                    className={styles.activityBody}
                    content={activity.body}
                  />
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  </div>
)
