import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import { GivePartnerPageQuery } from "../../../graphql/types"
import { defaultMetadata } from "../../core/constants"
import styles from "./give_partner.module.scss"
import { AboutStories } from "@features/about/components/about-stories/about-stories"
import { CallToAction } from "@components/call-to-action/call-to-action"
import AdditionalInfo from "@components/AdditionalInfo/additionalInfo"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { WhatWeDo } from "@features/about/components/what-we-do/what-we-do"
import { Activity, Story } from "@features/about/about-types"
import { Benefits } from "@features/give-partner/components/Benefits/Benefits"
import { Benefit, Testimony } from "@features/support/support-types"
import { FeaturedTestimonies } from "@features/support/components/featured-testimonies/featured-testimonies"
import { FaQs } from "@features/give-partner/components/FaQs/FaQs"
import { AccordionItem } from "@components/accordion-item/accordion-item"

export const GivePartnerPage: FunctionComponent<
  PageProps<GivePartnerPageQuery>
> = (props) => {
  const {
    seo,
    heroImage,
    heroTitle,
    heroOverline,
    heroPrimaryButtonText,
    heroPrimaryButtonUrl,
    reachTitle,
    reachDescription,
    reachButtonText,
    reachButtonUrl,
    storiesTitle,
    storiesDescription,
    story1Title,
    story1Description,
    story1YouTubeUrl,
    story2Title,
    story2Description,
    story2YouTubeUrl,
    impactTitle,
    impactDescription,
    impactCategories,
    benefitsTitle,
    benefitsDescription,
    benefitsBenefits,
    benefitsButtonText,
    benefitsButtonUrl,
    testimoniesImage,
    testimoniesTestimonies,
    faQsTitle,
    faQsQuestions
  } = props?.data?.contentfulGivePartner || {}

  const metadataOverrides = {
    title: seo?.title || defaultMetadata.title,
    description: seo?.description || defaultMetadata.description,
    openGraphImage: seo?.image?.fixed?.src
  }

  const stories: Array<Story> = [
    {
      title: story1Title || "",
      body: story1Description?.childMarkdownRemark?.rawMarkdownBody || "",
      youtubeId: story1YouTubeUrl || ""
    },
    {
      title: story2Title || "",
      body: story2Description?.childMarkdownRemark?.rawMarkdownBody || "",
      youtubeId: story2YouTubeUrl || ""
    }
  ]

  const mainActivities: Array<Activity> =
    impactCategories?.map((category) => ({
      title: category?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body: category?.description?.childMarkdownRemark?.rawMarkdownBody || "",
      image: category?.image?.fluid?.src || "",
      imageAltText: category?.image?.title || ""
    })) || []

  const benefits: Array<Benefit> =
    benefitsBenefits?.map((benefit) => ({
      text: benefit?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      image: benefit?.image?.fluid?.src || ""
    })) || []

  const testimonies: Array<Testimony> =
    testimoniesTestimonies?.map((testimony) => ({
      name: testimony?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body: testimony?.description?.childMarkdownRemark?.rawMarkdownBody || ""
    })) || []

  const questions: Array<AccordionItem> =
    faQsQuestions?.map((question) => ({
      title: question?.title || "",
      subtitle: question?.subtitle || "",
      body: question?.body as {
        childMarkdownRemark: { rawMarkdownBody: string }
      },
      image: question?.image as { fluid: FluidObject }
    })) || []

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      isInfoIconShown={true}
    >
      <div className={classNames(styles.section, styles.header)}>
        <CallToAction
          overline={heroOverline || ""}
          title={heroTitle || ""}
          buttonText={heroPrimaryButtonText || ""}
          buttonUrl={heroPrimaryButtonUrl || ""}
          imageFluid={heroImage?.fluid as FluidObject}
          variant="primary"
        />
      </div>
      <AdditionalInfo
        title={reachTitle || ""}
        body={reachDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
        buttonText={reachButtonText || ""}
        buttonUrl={reachButtonUrl || ""}
      />
      <div className={styles.section}>
        <AboutStories
          stories={{
            list: stories,
            title: storiesTitle || "",
            subtitle:
              storiesDescription?.childMarkdownRemark?.rawMarkdownBody || ""
          }}
        />
      </div>
      <div className={classNames(styles.section, styles.impact)}>
        <AdditionalInfo
          title={impactTitle || ""}
          body={impactDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
        />
        <WhatWeDo mainActivities={mainActivities} />
      </div>
      <div className={styles.section}>
        <Benefits
          title={benefitsTitle || ""}
          body={benefitsDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
          benefits={benefits}
          benefitsButtonUrl={benefitsButtonUrl || ""}
          benefitsButtonText={benefitsButtonText || ""}
        />
      </div>
      <div className={styles.section}>
        <FeaturedTestimonies
          items={testimonies}
          image={testimoniesImage?.fluid?.src || ""}
        />
      </div>
      <div className={styles.section}>
        <FaQs questions={questions} title={faQsTitle || ""} />
      </div>
    </Page>
  )
}

export default GivePartnerPage

export const query = graphql`
  query GivePartnerPage {
    contentfulGivePartner {
      seo {
        ...Metadata
      }
      heroImage {
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      heroTitle
      heroOverline
      heroPrimaryButtonText
      heroPrimaryButtonUrl
      reachTitle
      reachDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      reachButtonText
      reachButtonUrl
      storiesTitle
      storiesDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      story1Title
      story1Description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      story1YouTubeUrl
      story2Title
      story2Description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      story2YouTubeUrl
      impactTitle
      impactDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      impactCategories {
        image {
          title
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      benefitsTitle
      benefitsDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      benefitsBenefits {
        image {
          title
          fluid(maxWidth: 200) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      benefitsButtonText
      benefitsButtonUrl
      testimoniesImage {
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      testimoniesTestimonies {
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      faQsTitle
      faQsQuestions {
        title
        subtitle
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          title
          fluid(maxWidth: 100) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`
