import React, { FunctionComponent } from "react"
import { Carousel } from "../../../../components/carousel/carousel"
import { Stories } from "../../about-types"
import styles from "./about-stories.module.scss"
import { CardStory } from "./card-story"
import { Markdown } from "@components/markdown/markdown"

export interface AboutStoriesProps {
  stories: Stories
}

export const AboutStories: FunctionComponent<AboutStoriesProps> = ({
  stories
}) => (
  <div className={styles.aboutStories}>
    <div className={styles.content}>
      <div className={styles.text}>
        <div className={styles.title}>{stories.title}</div>
        <Markdown
          className={styles.readMore}
          content={stories.subtitle || ""}
        />
      </div>
      <div className={styles.carousel}>
        <Carousel>
          {stories.list.map((story, index) => (
            <CardStory key={index} {...story} />
          ))}
        </Carousel>
      </div>
    </div>
  </div>
)
